
  
  .mt-100{
    margin-top: 200px;
  }
  .progress {
    width: 150px;
    height: 150px !important;
    float: left; 
    line-height: 150px;
    background: none;
    margin: 20px;
    box-shadow: none;
    position: relative;
  }
  .progress:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 12px solid NONE;
    position: absolute;
    top: 0;
    left: 0;
  }
  .progress>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .progress .progress-left {
    left: 0;
  }
  .progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 12px;
    border-style: solid;
    position: absolute;
    top: 0;
  }
  .progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
  }
  .progress .progress-right {
    right: 0;
  }
  .progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards;
  }
  .progress .progress-value {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: none;
    font-size: 24px;
    color: #441a05;
    line-height: 135px;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%;
  }
  .progress.blue .progress-bar {
    border-color: #daa23d;
  }
  .progress.blue .progress-left .progress-bar {
    animation: loading-2 1.5s linear forwards 1.8s;
  }
  .progress.yellow .progress-bar {
    border-color: #daa23d;
  }
  .progress.yellow .progress-right .progress-bar {
    animation: loading-3 1.8s linear forwards;
  }
  .progress.yellow .progress-left .progress-bar {
    animation: none;
  }
  @keyframes loading-1 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  @keyframes loading-2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(144deg);
      transform: rotate(144deg);
    }
  }
  @keyframes loading-3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
  }
.nav-link{
  color: white
}
.nav-link:hover{
  color: white;
  border-bottom: 1px solid white;
}
.green-btn{
  color: rgb(0, 161, 48);
}
.green-btn:hover{
  background-color: rgb(0, 161, 48);
}

.yellow-btn{
  background-color: #daa23d; 
  padding: 13px 40px 13px 40px;
   border: 2px solid white;
   color: white;
}
.yellow-btn:hover{
  background-color: white; 
  padding: 13px 40px 13px 40px;
   border: 2px solid #daa23d;
   color: #daa23d;
}
.yellow-second-btn{
  border: none; width: 16%; height: 8vh; background-color :#daa23d; color: white
}
.yellow-second-btn:hover{
  border: none; width: 16%; height: 8vh; background-color :#946f2a; color: white
}
.footer-btn{
  border: none; width: 250px; height: 8vh; background-color:  #459880; color: white;
}
.footer-btn:hover{
  border: none; width: 250px; height: 8vh; background-color:  #2e6455; color: white;
}

/* responsive start */
@media screen and (max-width: 1250px){
  .Values{
    position: relative !important;
    display: inline-block !important;
    width: 100% !important;
  }
  .Values-img{
    margin-top: 10% !important;
    width: 700px !important;
    margin-left: 55% !important;
    height: 800px !important;
  }
  .Values-1{
    width: 80% !important;
     margin-left: 15% !important;
     margin-top: 80% !important 
  }
  .Values-2{
    width: 100% !important;
    font-size: 50px !important;
  }
  .Values-3{
    margin-left: 2% !important;
  }
  .Sec2{
    display: inline-block !important;
    margin-bottom: 40% !important;
    justify-content: center;
  }
  .Sec2-img{
    margin-top: 65% !important;
    width: 150% !important;
    margin-left: -22% !important;
  }
  .Sec2-1{
    margin-top: 10px !important;
    width: 750px !important;
    margin-left: -110px !important;
    margin-top: 2% !important;
  }
  .Sec2-2{
    margin-top: -1310px !important;
    width: 750px !important;
    margin-left: -110px !important;
  }
}
@media screen and (max-width: 1060px){
  .Faq-Lay{
    height: 1150px !important;
  }
  .Values{
    position: relative !important;
    display: inline-block !important;
    width: 100% !important;
  }
  .Values-img{
    margin-top: 10% !important;
    width: 600px !important;
    margin-left: 55% !important;
    height: 800px !important;
  }
  .Values-1{
    width: 80% !important;
     margin-left: 15% !important;
     margin-top: 90% !important 
  }
  .Values-2{
    width: 100% !important;
    font-size: 50px !important;
  }
  .Values-3{
    margin-left: 2% !important;
  }
}

@media screen and (max-width: 930px){
  .Values{
    position: relative !important;
    display: inline-block !important;
    width: 100% !important;
  }
  .Values-img{
    margin-top: 10% !important;
    width: 500px !important;
    height: 600px !important;
    margin-left: 55% !important;
  }
  .Values-1{
    width: 80% !important;
     margin-left: 10% !important;
     margin-top: 80% !important 
  }
  .Values-2{
    width: 100% !important;
    font-size: 40px !important;
  }
  .Values-3{
    margin-left: 2% !important;
  }
}

@media screen and (max-width: 899px){
  .head-btn{
    margin-Top: 320px !important;
  }
  .scrol-head{
    margin-top: 400px !important;
  }
  .scrol-i-head{
    margin-top: 450px !important;
  }
  .Sajda{
    display: inline-block !important;
  }
  .Sajda1{
    width: 100% !important;
    margin-bottom: 10% !important;
    margin-left: -2% !important; 
  }
  .Sajda2{
    width: 90% !important;
    margin-left: 10% !important;
  }
  .Sajda-h{
    font-size: 8vh !important;
    width: 100% !important;  
  }
  .Div-foot{
    display: inline-block !important;
    justify-content: center;
    width: 90% !important;
    margin-left: -40% !important;
  }
  .Div-foot-contact{
    display: inline-block !important;
    justify-content: center;
    width: 90% !important;
    margin-left: 5% !important;
  }
  .Div-foot-bg{
    height: 1440px !important;
  }
  .Div-foot-bg-contact{
    height: 1040px !important;
  }
  .Div-foot-logo{
    width: 20% !important;
    margin-left: -10% !important;
  }
  .Div-foot1{
    width: 40% !important;
    margin-left: 1% !important;
    margin-top: 5% !important;
  }
  .Div-foot2{
    width: 40% !important;
    margin-left: -14% !important;
    margin-top: 5% !important;
  }
  .Div-foot3{
    width: 100% !important;
    margin-left: 2% !important;
    margin-top: 10% !important;
  }
  .Div-foot4{
    margin-top: 1405px !important  }
}
.Div-foot4-contact{
  margin-top: 990px !important  
}
@media screen and (max-width: 851px){
 .Contact-flex1-foot{
   display: inline-block !important;
 }
 .Contact-flex1-foot-bg{
   height: 1000px !important;
 }
 .Contact-flex1-foot-last{
   margin-top: 950px !important;
 }
 .Contact-flex1-foot0{
   width: 50% !important;
   margin-bottom: 2% !important;
 }
 .Contact-flex1-foot1{
margin-bottom: 5% !important;
}
.Contact-flex1-foot2{
  margin-bottom: 5% !important;
   margin-left: 16% !important;
}
.Contact-flex1-foot3{
  width: 50% !important;
   margin-left: 23% !important;
}
  .Sec-3{
    width: 70% !important;
  }
  .Sec2{
    display: inline-block !important;
    margin-bottom: 290px !important;
    justify-content: center;
  }
  .Sec2-img{
    margin-top: 75% !important;
    width: 145% !important;
    margin-left: -22% !important;
  }
  .Sec2-1{
    margin-top: 10px !important;
    width: 720px !important;
    margin-left: -110px !important;
    margin-top: 2% !important;
  }
  .Sec2-2{
    margin-top: -1310px !important;
    width: 720px !important;
    margin-left: -110px !important;
  }
}
@media screen and (max-width: 785px){
  .Card{
    display: inline-block !important;
  }
  .card{
    margin-bottom: 10% !important;
  }
}
@media screen and (max-width:730px){
  .Contact-flex-form-H{
    height: 900px !important;
  }
  .Contact-flex-form{
    display: inline-block !important;
  }
.Contact-flex-form-c1{
  width: 70% !important;
}

.Contact-flex-form-c2{
  width: 70% !important;
  height: 600px !important;
  padding-top: 7px !important;
}
  .Contact-flex1{
    display: inline-block !important;
    margin-left: 14% !important;  
}
.Contact-flex1-C{
width: 80% !important;
}
  .Contact-flex1-H{
    height: 1200px !important;
  }
  .Sec2-img{
    margin-top: 80% !important;
    width: 130% !important;
    margin-left: -15% !important;
  }
  .Sec2-1{
    width: 650px !important;
    margin-left: -75px !important;
    margin-top: 5% !important;
  }
  .Sec2-2{
    margin-top: -1250px !important;
    width: 650px !important;
    margin-left: -75px !important;
  }
  .Sec2-3{
    margin-top: 10% !important;
  }
  .Values-img{
    margin-top: 30% !important;
    width: 580px !important;
  }
  .Values-1{
    width: 100% !important;
    margin-left: 0% !important;
    margin-top: 700px !important;
    padding-left: 10% !important;
  }
  .Values-2{
    width: 92% !important;
  }
  .Values-3{
    margin-left: -5% !important;
  }
}
@media screen and (max-width: 682px){
  .head-btn{
    margin-Top: 390px !important;
  }
  .scrol-head{
    margin-top: 490px !important;
  }
  .scrol-i-head{
    margin-top: 530px !important;
  }
  .Values-img{
    margin-top: 30% !important;
    width: 500px !important;
    margin-left: 20vh !important;
  }
  .Values-1{
    width: 100% !important;
    margin-left: 0% !important;
    margin-top: 700px !important;
    padding-left: 10% !important;
  }
  .Values-2{
    width: 92% !important;
  }
  .Values-3{
    margin-left: -5% !important;
  }
  .Div-Icon1{
    display: inline-block !important;
  }
  .Div-Icon2{
    width: 70% !important;
    margin-left: 20% !important;
  }
  .yellow-second-btn{
    margin-top: 10% !important;
    width: 250px !important;
  }
  .Div-Icon-Y{
    display: inline-block !important;
  }
  .Div-Icon-Y1{
    width: 100% !important;
    margin-bottom: 10% !important;
  }
}
@media screen and (max-width:650px) {
  .Sec2-img{
    margin-top: 80% !important;
    width: 450px !important;
    margin-left: 4% !important;
  }
  .Sec2-1{
    width: 450px !important;
    margin-left: 15px !important;
    margin-top: 5% !important;
  }
  .Sec2-2{
    margin-top: -950px !important;
    width: 450px !important;
    margin-left: 15px !important;
  }
  .Sec2-3{
    margin-top: 20% !important;
  }
}
@media screen and (max-width:637px) {
  .Faq-Lay{
    height: 1300px !important;
  }
}
@media screen and (max-width:600px) {
  .Values-img{
    margin-top: 30% !important;
    width: 450px !important;
    height: 500px !important;
    margin-left: 20vh !important;
  }
  .Values-1{
    width: 100% !important;
    margin-left: 0% !important;
    margin-top: 600px !important;
    padding-left: 10% !important;
  }
  .Values-2{
    width: 92% !important;
  }
  .Values-3{
    margin-left: -5% !important;
  }
  .Progress-W{
    display: inline-block !important;
    width: 100% !important;
    margin-left: 20% !important;
  }
}
@media screen and (max-width:562px) {
  .Faq-Lay{
    height: 1400px !important;
  }
}
@media screen and (max-width:554px) {
  
  
  .Div-foot-bg{
    height: 1300px !important;
  }
  .Div-foot4{
    margin-top: 1250px !important  
  }
  .View-More{
    display: none !important;
  }
  .Values-img{
    margin-top: 30% !important;
    width: 350px !important;
    height: 400px !important;
    margin-left: 20vh !important;
  }
  .Values-1{
    width: 100% !important;
    margin-left: 0% !important;
    margin-top: 500px !important;
    padding-left: 10% !important;
  }
  .Values-2{
    width: 92% !important;
  }
  .Values-2p{
    width: 100% !important;
  }
  .Values-3{
    width: 95% !important;
    margin-left: -5% !important;
  }
  .Big-text{
    font-size: 30px !important;
  }
  .Big-text-img{
    height: 400px !important;
  }
  .big-text-foot{
    font-size: 30px !important;
  }
  
  .Div-foot-main{
    margin-top: 450px !important;
  }
}
@media screen and (max-width:522px) {
  .Div-Icon1{
    display: inline-block !important;
    margin-top: 150px !important;
  }
  .Sec-3{
    width: 80% !important;
    margin-top: 70px !important;
  }
  .Sec2-img{
    margin-top: 110% !important;
    width: 380px !important;
    height: 500px;
    margin-left: -1% !important;
  }
  .Sec2-1{
    width: 380px !important;
    margin-left: -3px !important;
    margin-top: 10% !important;
  }
  .Sec2-2{
    height: 350px !important;
    margin-top: -890px !important;
    width: 360px !important;;
    margin-left: 5px !important;
  }
  .Sec2-3{
    margin-top: 20% !important;
  }
}

@media screen and (max-width:457px) {
  .Faq-Lay{
    height: 1550px !important;
  }
  .Contact-flex-form-H{
    height: 900px !important;
}
.Contact-flex-form-c1H{
  font-size: 30px !important;
}
.Contact-flex-form-c2{
  margin-top: 5% !important;
  width: 100% !important;
  height: 550px !important;
  padding-top: 7px !important;
  margin-left: -5% !important;
}
}
@media screen and (max-width: 468px){
  .Allah-text{
    font-size: 45px !important
  }
  .scrol-head{
    width: 30% !important 
  }
  .Values-img{
    margin-top: 30% !important;
    width: 350px !important;
    height: 370px !important;
    margin-left: 15vh !important;
  }
  .Values-1{
    width: 100% !important;
    margin-left: 0% !important;
    margin-top: 500px !important;
    padding-left: 10% !important;
  }
  .Values-2{
    width: 100% !important;  }
  .Values-3{
    margin-left: -5% !important;
  }
  .Card{
    display: inline-block !important;
    width: 38% !important;
  }
  .card{
    margin-left: -70% !important;
    width: 250% !important;
    margin-bottom: 10% !important;
  }
  .Sajda2c{
    width: 65% !important;
    margin-left: 35% !important;
  }
  .Sajda2{
    width: 90% !important;
    margin-left: 1% !important;
  }
  .Sajda-h{
    font-size: 8vh !important;
    width: 200% !important;  
  }
  .Sec-3{
    width: 80% !important;
    margin-top: 70px !important;
  }
  .Sec2-img{
    margin-top: 110% !important;
    width: 380px !important;
    height: 500px;
    margin-left: -1% !important;
  }
  .Sec2-1{
    width: 380px !important;
    margin-left: -3px !important;
    margin-top: 10% !important;
    margin-right: -20% !important;
  }
  .Sec2-2{
    height: 350px !important;
    margin-top: -890px !important;
    width: 360px !important;;
    margin-left: 5px !important;
  }
  .Sec2-3{
    margin-top: 20% !important;
  }
}
@media screen and (max-width:415px) {
  .Div-foot-bg{
    height: 1250px !important;
  }
  .Div-foot4{
    margin-top: 1200px !important  
  }
  .Values-img{
    margin-top: 20% !important;
    width: 300px !important;
    height: 340px !important;
    margin-left: 15vh !important;
  }
  .Values-1{
    width: 100% !important;
    margin-left: 0% !important;
    margin-top: 450px !important;
    padding-left: 10% !important;
  }
  .Progress-W{
    display: inline-block !important;
    width: 100% !important;
    margin-left: 8% !important;
  }
  .Images{
    width:360px !important;
    margin-left: 10% !important;
  }
}

@media screen and (max-width: 398px){
  .Allah-text{
    font-size: 40px !important
  }
  .Sec-3{
    width: 80% !important;
    margin-top: 60px !important;
  }
  .Sec2-img{
    margin-top: 140% !important;
    width: 270px !important;
    height: 400px;
    margin-left: 2% !important;
  }
  .Sec2-1{
    width: 270px !important;
    margin-left: 4px !important;
    margin-top: 10% !important;

  }
  .Sec2-2{
    height: 350px !important;
    margin-top: -790px !important;
    width: 270px !important;;
    margin-left: 5px !important;
  }
  .Sec2-3{
    margin-top: 49% !important;
  }
}
@media screen and (max-width:360px) {
  .Values-img{
    margin-top: 20% !important;
    width: 300px !important;
    height: 340px !important;
    margin-left: 5vh !important;
  }
  .Sec2-3{
    margin-top: 55% !important;
  }
}
@media screen and (max-width: 354px){
  .Allah-text{
    margin-top: 60% !important;
    font-size: 32px !important
  }
  .Contact-flex-form-c1H{
    font-size: 25px !important;
    font-weight: bolder;
  }
}
@media screen and (max-width:335px) {
  .Faq-Lay{
    height: 1650px !important;
  }
  .Icon{
    font-size: 20px !important;
    padding: 5px 0px !important;
  }
}
@media screen and (max-width:320px) {
  .Values-img{
    margin-top: 20% !important;
    width: 250px !important;
    height: 300px !important;
    margin-left: 9vh !important;
  }
  .Values-1{
    width: 100% !important;
    margin-left: 0% !important;
    margin-top: 400px !important;
    padding-left: 5% !important;
  }
  .Progress-W{
    display: inline-block !important;
    width: 100% !important;
    margin-left: -3% !important;
  }
  .Values{
    position: relative !important;
    display: inline-block !important;
    width: 100% !important;
  }
  .Values-H{
    margin-top: -2% !important;
  }
}